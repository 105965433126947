import React, {useState} from 'react';
import {Button, Form, List as AntdList, Modal, Table} from 'antd';
import styled from 'styled-components';
import PaidSelect from "components/paid-select";
import ReceivedSelect from "components/received-select";
import ProductStatus from "components/order/ProductStatus";
import SignatureCanvas from 'react-signature-canvas';
import axios from 'utils/axios';

import Signature from "components/signature";

export default function SignDocument(props) {
    let signatureRef;

    function handleOk() {
        const data = new FormData();

        signatureRef.getCanvas().toBlob(blob => {
            data.append('file', blob);

            axios
                .post('/api/upload', data)
                .then(response => {
                    axios
                        .post('/api/commands/sign-document', {
                            document_id: props.id,
                            signature: response.data,
                        })
                        .then(() => {
                            props.onCancel();
                        })
                    ;
                })
            ;
        });
    }

    function DocumentProducts(props) {
        return (
            <AntdList
                bordered
                dataSource={props.data}
                renderItem={item => (
                    <AntdList.Item>
                        {item.quantity} x {item.name} {item.additional_notes && item.additional_notes.trim().length > 0 ? ` / ${item.additional_notes}` : ''}
                    </AntdList.Item>
                )}
            />
        )
    }

    return (
        <Modal visible={!!props.id} {...props} onOk={handleOk}>
            <Signature reference={ref => signatureRef=ref} width={600} height={200} />
            <DocumentProducts data={props.products} />
        </Modal>
    );
}
